.error-image {
    height: 250px;
    width: 100%;
}
.a-tag {
    color: #00BEDC !important;
    font-style: italic !important;
    font-style: italic !important;
    text-decoration: underline !important;
}
