$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.landing-cognisphere {
    position: fixed;
    top: -40vh;
    right: 25vw;

    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        display: none;
    }

    @media screen and (min-width: $mediumTablet) and (max-width: $desktop) {
        display: block;
        position: fixed;
        right: 25vw;
        bottom: -22vh;
    }
}