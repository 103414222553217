.subTitle {
  font-size: 15px;
  font-family: "SiemensSans-Black", sans-serif;
  font-weight: bolder;
  color: var(--dark-blue);
  margin: 10px 0px 10px 0px;
}
.contentBody {
  font-size: 13px;
}
.imgCont {
  padding-top: 10px;
}
