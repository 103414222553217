.electrification-homepage--card--block {
  display: flex;
  align-items: center;
  /*flex-wrap: wrap;*/
}
.electrification-homepage--card--block-item {
  height: 225px;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 10px 10px #cccccc;
  transition: 0.25s;
  /*margin: 0 30px;*/
  border-top: 2px solid #cccccc33;
  border-left: 2px solid #cccccc33;
}

.electrification-homepage--card--block-item:hover {
  box-shadow: 1px 1px 10px #aaaa9633;
  transform: scale(1.05);
  border-top: 10px solid transparent;
  border-left: 2px solid #cccccc33;
}
.link-button {
  width: 100% !important;
  background: transparent !important;
  border: none !important;
}
.link-button:disabled {
  cursor: no-drop !important;
}

.electrification-homepage--card--block-item-text {
  text-transform: uppercase;
  color: var(--white);
  background: #002949e6;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 101%;
  padding: 15px;
  font-size: 14px;
  text-align: center;
  transition: 0.75s;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.electrification-homepage--card--block-item--electrification {
  background: url(../../assets/images/electrification.jpg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.electrification-homepage--card--block-item--qrcalibration {
  background: url(../../assets/images/scanner.jpg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.menuCont {
  align-items: center;
  justify-content: center;
}
